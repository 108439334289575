<template>
  <div class="d-lg-block d-none">
    <slot />
  </div>

  <CAccordionItem :item-key="index">
    <CAccordionHeader
      @click="open"
    >
      <slot v-if="$slots.title" name="title" />
      <span>{{title}}</span>
    </CAccordionHeader>
    <CAccordionBody>
      <slot />
    </CAccordionBody>
  </CAccordionItem>

</template>

<script>
export default {
  emits: ['open'],
  props: { title: { type: String, default: '' }, index: { type: Number, default: 0 } },
  methods: {
    open() {
      this.$emit('open')
    }
  }
}
</script>