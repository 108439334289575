import table from '@/mixin/table'
import Filter from './components/Filter'
import Table from './components/Table'
import Tags from './components/Tags'
import Wrap from './components/Wrap'

export default {
  name: 'CompaniesList',
  components: { Filter, Table, Tags, Wrap },
  inject: ['mountedComponent'],
  mixins: [table],
  mounted() {
    this.$http.projects
      .tagsDocuments({ params: { project_id: this.$route.params.id } })
      .then((res) => {
        this.tagsList = res.data.data.map((i) => ({
          value: i.id,
          label: i.name,
        }))
      })
  },
  computed: {
    isMobile() {
      return this.windowWidth < 576
    },
  },
  data() {
    return {
      type: 'archive',
      tags: [],
      tagsList: [],
      filterList: [
        { label: 'Quick Access', value: 'quick_access', icon: 'cilStar' },
        { label: 'Pending documents', value: 'documents', icon: 'cilDescription' },
        { label: 'Completed documents', value: 'archive', icon: 'cilStorage' },
      ],
    }
  },
}
