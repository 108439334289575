<template>
  <img v-if="active" src="@/assets/star-solid.svg" />
  <img v-else src="@/assets/star-regular.svg" />
</template>

<script>
export default {
  props: { active: { type: Boolean, default: false }}
}
</script>

<style lang="scss" scoped>
img {
  height: 16px;
  width: 16px;
  object-fit: contain;
  cursor: pointer;
}
</style>