<template>
  <div class="tags">
    <div class="tags__item tags__label" v-if="labelVis">
      Task Tags
    </div>
    <div
      v-for="item in listView"
      :key="item.value"
      class="tags__item"
    >
      <div class="mr-2">
      <CFormCheck
        :checked="modelValue && (modelValue.findIndex(i => i === item.value) !== -1)"
        @click="setValue(item.value)"
      />
      </div>
      {{item.label}}
    </div>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {},
    list: { type: Array, },
    labelVis: { type: Boolean, default: true }
  },
  data: () => {
    return {
    }
  },
  computed: {
    listView() {
      return this.list
    }
  },
  methods: {
    setValue(value) {
      let arr = [...this.modelValue]
      let findIndex = this.modelValue.findIndex(i => i === value)
      if (findIndex === -1) {
        arr.push(value)
      } else {
        arr.splice(findIndex, 1)
      }
      this.$emit('update:modelValue', arr)
    }
  }
}
</script>

<style lang="scss" scoped>
.tags {
  padding: 0 0 5px 0;
  width: 200px;
  max-width: 200px;
  min-width: 200px;
  max-height: 500px;
  overflow: auto;

  &__label {
    font-size: 18px!important;
    font-weight: 700!important;
  }

  &__item {
    color: #303C54;
    padding: 6px 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;

    &-icon {
      margin-right: 12px;
    }
  }
}
</style>