<template>
  <div class="filter">
    <div
      v-for="item in listView"
      :key="item.value"
      :class="{'filter__item': true, 'filter__item-active': modelValue === item.value}"
      @click="$emit('update:modelValue', item.value)"
    >
      <CIcon :name="item.icon" class="filter__item-icon"/>
      {{item.label}}
    </div>
  </div>
</template>

<script>
export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {},
    list: { type: Array, } 
  },
  data: () => ({
    // view: 2
  }),
  computed: {
    listView() {
    //   return [...this.list].splice(0, this.view)
      return this.list
    }
  }
}
</script>

<style lang="scss" scoped>
.filter {
  padding: 0 0 5px 0;

  &__item {
    color: #303C54;
    padding: 6px 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;

    &-icon {
      margin-right: 12px;

      &.view {
        margin-right: 0;
        margin-left: 5px;
      }
    }

    &-active {
      background: #005D9D;
      color: #ffffff;
    }

    &.view {
      font-size: 12px;
      color: #005D9D;
    }
  }
}
</style>