<template>
  <div
    class="d-flex align-items-center mb-2 mt-2 mx-3 flex-wrap flex-md-nowrap"
  >
    <div class="d-block d-md-flex w-100">
      <CDropdown
        :key="`${selected.length}-${type}`"
        color="primary"
        class="mr-2 mb-2 w-md-auto w-100 d-flex"
        style="min-width: 200px"
      >
        <CDropdownToggle
          :key="`${selected.length}`"
          color="primary"
          variant="outline"
          :disabled="!selected.length"
          class="text-start d-flex align-items-center justify-content-between"
        >
          Bulk Action
        </CDropdownToggle>
        <CDropdownMenu>
          <!-- <CDropdownItem v-if="type === 'archive'" @click="restore">
            Restore
          </CDropdownItem> -->
          <CDropdownItem @click="download"> Download </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
      <CInputGroup class="mr-2 mb-2 w-md-auto w-100">
        <CInputGroupText><CIcon name="cil-search" /></CInputGroupText>
        <CFormInput v-model="search" placeholder="Search" />
      </CInputGroup>
    </div>
  </div>
  <div class="px-2 documents-table">
    <SmartTable
      :items="list"
      :items-per-page="parseInt(perPage)"
      :columns="fields"
      selectable="task_id"
      :sorter-value="sort"
      :selected-items="selected"
      :loading="loadingTable"
      :active-page="activePage"
      :per-page="perPage"
      :pages="pages"
      :clear-text="search.length ? 'No search results found' : 'No Data yet'"
      @sorter-change="onSort"
      @selected-items-change="(s) => (selected = s)"
      @update-active-page="(value) => (activePage = value)"
      @update-per-page="(value) => (perPage = value)"
    >
      <template #quick_access="{ item }">
        <td>
          <Star :active="item.quick_access" @click="changeFav(item)" />
        </td>
      </template>
<!--      <template #file_name="{ item, column }">-->
<!--        <div class="table-file-name">-->
<!--          <a @click="goToView(item)">-->
<!--            {{ item.file_name }}-->
<!--          </a>-->
<!--          <CBadge v-if="column.key === 'file_name'" color="primary">-->
<!--            {{ item.status }}-->
<!--          </CBadge>-->
<!--        </div>-->
<!--      </template>-->

      <template #status="{ item, column }">
        <CBadge v-if="column.key === 'file_name'" color="primary">{{
          item.status
        }}</CBadge>
      </template>

      <template #clear_text>
        <div
          class="table__clear d-flex align-items-center justify-content-center mb-2 w-100"
        >
          <CIcon class="table__clear__icon" size="6xl" name="cilDescription" />
          <div class="table__clear__title">No documents yet</div>
          <div class="table__clear__title-sub">
            Add document from library or from computer
          </div>
          <CButton
            color="primary"
            class="table__clear__btn"
            @click="handleCreateTask"
            >Add Tasks</CButton
          >
        </div>
      </template>
    </SmartTable>
    <div
      v-if="loadingTable"
      class="d-flex align-items-center justify-content-center mb-2"
    ></div>
  </div>
</template>

<script>
import table from '@/mixin/table'
import Star from '@/components/Star'
import { updatePlan, permissionError } from '@/services/Notify/Toasts'
import Gate from '@/services/Gate/Gate'
import onTimeoutSearch from '@/mixin/searchTimeout'

export default {
  components: { Star },
  mixins: [table, onTimeoutSearch],
  inject: ['downloadToast'],
  props: {
    tags: {},
    type: {},
  },
  data: (v) => ({
    selected: [],
    loadingTable: false,
    list: [],
    fields: [
      {
        key: 'quick_access',
        label: '',
        _style: 'max-width: 30px; min-width: auto',
        sorter: false,
      },
      {
        key: 'file_name',
        label: 'File Name',
        _style: '',
        click: (item) => v.goToView(item),
      },
      {
        key: 'task_name',
        label: 'Task Name',
        _style: '',
        link: {
          name: 'TaskContracts',
          params: { id: 'project_id', task_id: 'task_id' },
        },
      },
      {
        key: 'company',
        label: 'Company',
        _style: '',
      },
      { key: 'due_date', label: 'Due Date', _style: '' },
      { key: 'priority', label: 'Priority', _style: '' },
      {
        key: 'internal_owner',
        label: 'Internal Owner',
        _style: '',
      },
      {
        key: 'assigned_to',
        label: 'Assigned To',
        _style: '',
      },
    ],
  }),
  watch: {
    search() {
      // this.onTimeoutSearch(() => this.fetchData, 'documents');
      this.selected = []
    },
    'tags.length'() {
      this.fetchData()
      this.selected = []
    },
    type() {
      this.fetchData()
      this.selected = []
    },
  },
  methods: {
    async checkPermission() {
      const check = await Gate.can('tabAction', 'project', this.$route.params.id)
      if (!check) {
        if (this.$store.getters.isSubPlan) this.$notify(updatePlan)
        if (this.$store.getters.isGeneralPlan) this.$notify(permissionError)
      }
      return check
    },
    async handleCreateTask() {
      if (await this.checkPermission()) {
        this.$router.push({ name: 'ProjectTasksCreate', params: { id: this.$route.params.id } })
      }
    },
    goToView(item) {
      if (item.type === 'Request documents') {
        this.$router.push({
          name: 'TaskRequest',
          params: { id: this.$route.params.id, task_id: item.task_id },
        })
      } else if (item.type === 'Contracts') {
        this.$router.push({
          name: 'TaskContracts',
          params: { id: this.$route.params.id, task_id: item.task_id },
        })
      } else if (item.type === 'Acknowledge') {
        this.$router.push({
          name: 'TaskAcknowledge',
          params: { id: this.$route.params.id, task_id: item.task_id },
        })
      }
    },
    fetchData(clear = true) {
      if (clear) {
        this.loadingTable = true
        this.list = []
      }
      this.$http.projects
        .projectDocuments({
          params: {
            project_id: this.$route.params.id,
            per_page: this.perPage,
            page: this.activePage,
            sort_field: (this.sort && this.sort.column) || 'due_date',
            sort_direction: (this.sort && this.sort.state) || 'desc',
            search: this.search,
            tags: this.tags,
            archive: this.type === 'archive',
            quick_access: this.type === 'quick_access',
          },
        })
        .then((res) => {
          this.pages = res.data.meta.last_page
          this.list = res.data.data.map((i) => ({
            ...i,
            file_name: i.file_name,
            task_name: i.task_name,
            company: i.company,
            company_id: i.company_id,
            due_date: i.due_date,
            status: i.status,
            priority: i.priority,
            internal_owner: i.internal_owner,
            assigned_to: i.assigned_to,
            project_id: this.$route.params.id,
          }))
        })
        .finally(() => {
          this.key++
          this.loadingTable = false
        })
    },
    download() {
      this.$http.common
        .downloadInitJob({
          action: 'project_documents',
          project_id: this.$route.params.id,
          tasks: this.selected,
        })
        .then(({ data }) => {
          this.downloadToast.open(data.download_token, data.preview_title)
        })
        .catch((error) => this.toast('error', error.response.data.message))
    },
    restore() {},
    setCheck(value) {
      this.selected = value.map((i) => i.task_id)
    },
    changeFav(item) {
      if (item.quick_access) {
        this.$http.projects
          .removeToQuickAccess({ tasks: [item.task_id] })
          .then(() => {
            this.fetchData(false)
          })
      } else {
        this.$http.projects
          .addToQuickAccess({ tasks: [item.task_id] })
          .then(() => {
            this.fetchData(false)
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table__clear {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px 30px 60px;
  background: #f5f7f9;

  &__icon {
    color: #b1b7c1;
    margin-bottom: 20px;
  }

  &__title {
    margin-bottom: 12px;
    font-weight: 700;
    font-size: 18px;
    color: #303c54;

    &-sub {
      margin-bottom: 35px;
      font-weight: 400;
      font-size: 14px;
      color: #465967;
    }
  }
}
</style>
